"use client"

import {
  createContext,
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
} from "react"

import { usePathname, useSearchParams } from "next/navigation"

import { useImpersonation } from "@supernovaio/cloud/features/impersonation"
import { getSegment } from "@supernovaio/cloud/features/segment/getSegment"
import {
  SegmentEventBase,
  SegmentGroupData,
  SegmentPageMetadata,
  SegmentUserData,
} from "@supernovaio/cloud/features/segment/types"
import {
  convertKeysToSnakeCase,
  addKeysPrefix,
} from "@supernovaio/cloud/features/segment/utils"

import {
  AnalyticsBrowser,
  EventProperties,
  UserTraits,
} from "@segment/analytics-next"
import { captureException } from "@sentry/nextjs"

const KEY_PREFIX = "sn"

type TrackedPageData = {
  pathname: string
  searchParamsSubPath: string
}

// Define the shape of the context
interface SegmentContextData {
  identify: (data: SegmentUserData) => Promise<void>
  trackEvent: (data: SegmentEventBase) => Promise<void>
  trackPage: (data: SegmentPageMetadata) => Promise<void>
  group: (data: SegmentGroupData) => Promise<void>
  reset: () => Promise<void>
}

// Create the context
const SegmentContext = createContext<SegmentContextData | undefined>(undefined)

// Create the provider component
export function SegmentProvider({ children }: PropsWithChildren) {
  const analyticsRef = useRef<AnalyticsBrowser | null>(null)
  const { isImpersonating } = useImpersonation()

  const pathname = usePathname()
  const searchParams = useSearchParams()

  const lastSubmittedIdentityRef = useRef<SegmentUserData | null>(null)
  const lastSubmittedGroupDataRef = useRef<SegmentGroupData | null>(null)
  const lastTrackedPageDataRef = useRef<TrackedPageData | null>(null)

  // Init analytics
  useEffect(() => {
    analyticsRef.current = getSegment()
  }, [])

  const identifyCallback = useCallback(
    async (data: SegmentUserData) => {
      if (isImpersonating) {
        return
      }

      const lastSubmittedIdentity = lastSubmittedIdentityRef.current
      if (
        lastSubmittedIdentity &&
        areDataObjectsEqual(data, lastSubmittedIdentity)
      ) {
        // Do not submit the same identity
        return
      }
      lastSubmittedIdentityRef.current = data

      const { id, ...traits } = data
      const analytics = analyticsRef?.current

      const snakeCaseTraits = convertKeysToSnakeCase(traits) as UserTraits
      const prefixedTraits = addKeysPrefix(snakeCaseTraits, KEY_PREFIX, [
        "email",
        "name",
      ])
      await analytics?.identify(id, prefixedTraits)
    },
    [isImpersonating]
  )

  const trackEventCallback = useCallback(
    async ({ eventName, ...properties }: SegmentEventBase) => {
      if (isImpersonating) {
        return
      }

      const analytics = analyticsRef?.current

      const snakeCaseProperties = convertKeysToSnakeCase(
        properties
      ) as EventProperties
      const prefixedProperties = addKeysPrefix(snakeCaseProperties, KEY_PREFIX)
      await analytics?.track(eventName, prefixedProperties).catch((error) => {
        captureException(error, {
          extra: {
            eventProperties: properties,
          },
        })
      })
    },
    [isImpersonating]
  )

  const trackPageCallback = useCallback(
    async ({ category, name }: SegmentPageMetadata) => {
      if (isImpersonating) {
        return
      }

      const currentPageData = {
        pathname,
        searchParamsSubPath: searchParams.toString(),
      }

      const lastTrackedPageData = lastTrackedPageDataRef.current
      if (
        lastTrackedPageData &&
        areDataObjectsEqual(currentPageData, lastTrackedPageData)
      ) {
        // Do not track the same page regardless of the metadata
        return
      }
      lastTrackedPageDataRef.current = currentPageData

      const analytics = analyticsRef?.current
      // We need to fall back to empty strings, because otherwise some weird shenanigans happen under the hood:
      // when only `category` is set, it's sent as `name`
      await analytics?.page(category || "", name || "")
    },
    [isImpersonating, pathname, searchParams]
  )

  const groupCallback = useCallback(
    async (data: SegmentGroupData) => {
      if (isImpersonating) {
        return
      }

      const lastSubmittedGroupData = lastSubmittedGroupDataRef.current
      if (
        lastSubmittedGroupData &&
        areDataObjectsEqual(data, lastSubmittedGroupData)
      ) {
        // Do not submit the same group id to the analytics
        return
      }
      lastSubmittedGroupDataRef.current = data

      const { workspaceId, ...traits } = data
      const snakeCaseTraits = convertKeysToSnakeCase(traits) as UserTraits
      const prefixedTraits = addKeysPrefix(snakeCaseTraits, KEY_PREFIX)

      const analytics = analyticsRef?.current
      await analytics?.group(workspaceId, prefixedTraits)
    },
    [isImpersonating]
  )

  const resetCallback = useCallback(async () => {
    const analytics = analyticsRef?.current
    await analytics?.reset()
  }, [])

  const contextValue = useMemo(
    () => ({
      identify: identifyCallback,
      trackEvent: trackEventCallback,
      trackPage: trackPageCallback,
      group: groupCallback,
      reset: resetCallback,
    }),
    [
      identifyCallback,
      trackEventCallback,
      trackPageCallback,
      groupCallback,
      resetCallback,
    ]
  )

  return (
    <SegmentContext.Provider value={contextValue}>
      {children}
    </SegmentContext.Provider>
  )
}

// Create a custom hook to access the context
export const useSegment = (): SegmentContextData => {
  const context = useContext(SegmentContext)

  if (context === undefined) {
    throw new Error("useSegment must be used within SegmentProvider")
  }

  return context
}

type AllowedValue = boolean | string | undefined | null

function areDataObjectsEqual<T extends Record<string, AllowedValue>>(
  a: T,
  b: T
): boolean {
  const keysA = Object.keys(a) as (keyof T)[]
  const keysB = Object.keys(b) as (keyof T)[]

  if (keysA.length !== keysB.length) {
    return false
  }

  return keysA.every((key) => a[key] === b[key])
}
