import { type ClassValue, clsx } from "clsx"
import { extendTailwindMerge } from "tailwind-merge"

const twMerge = extendTailwindMerge({
  classGroups: {
    text: [
      "text-title-1",
      "text-title-2",
      "text-title-3",
      "text-title-4",
      "text-title-5",
      "text-title-6",
      "text-body",
      "text-body-small",
      "text-body-large",
      "text-caption",
      "text-caption-small",
      "text-docs-title-1",
      "text-docs-title-2",
      "text-docs-title-3",
      "text-docs-title-4",
      "text-docs-title-5",
      "text-docs-quote",
      "text-docs-title-description",
      "text-docs-body",
      "text-docs-body-small",
      "text-docs-body-medium",
    ],
  },
})

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs))
}
