import * as React from "react"

import { cn } from "@supernovaio/dm/src/utils/cn"

import { Slot } from "@radix-ui/react-slot"

import "./styles.css"

type DMSkeletonProps = {
  isLoading?: boolean
  width?: string | number
  height?: string | number
  minWidth?: string | number
  minHeight?: string | number
  maxWidth?: string | number
  maxHeight?: string | number
  children?: React.ReactNode
}

/**
 * Skeleton component, used to indicate loading state.
 * Supports two modes:
 * - Explicit size mode: specify `width`, `height`, `minWidth`, `minHeight`, `maxWidth`, or `maxHeight`.
 * - Implicit size mode: wrap `children` with the component.
 */
export function DMSkeleton({
  isLoading = true,
  width,
  height,
  minWidth,
  minHeight,
  maxWidth,
  maxHeight,
  children,
}: DMSkeletonProps) {
  if (!isLoading) {
    // eslint-disable-next-line react/jsx-no-useless-fragment
    return <>{children}</>
  }

  const hasValidChildren = React.isValidElement(children)
  const Tag = hasValidChildren ? Slot : "span"

  const normalizedWidth = normalizeDimension(width)
  const normalizedHeight = normalizeDimension(height)
  const normalizedMinWidth = normalizeDimension(minWidth)
  const normalizedMinHeight = normalizeDimension(minHeight)
  const normalizedMaxWidth = normalizeDimension(maxWidth)
  const normalizedMaxHeight = normalizeDimension(maxHeight)

  return (
    <Tag
      aria-hidden
      className={cn(
        "dm-skeleton",
        "!animate-pulse !bg-border-neutral-faded",
        "!bg-none !bg-clip-border !border-none !shadow-none !box-decoration-clone !text-transparent",
        "!outline-none !pointer-events-none !select-none !cursor-default"
      )}
      style={{
        width: normalizedWidth,
        height: normalizedHeight,
        minWidth: normalizedMinWidth,
        minHeight: normalizedMinHeight,
        maxWidth: normalizedMaxWidth,
        maxHeight: normalizedMaxHeight,
      }}
      data-inline-skeleton={hasValidChildren ? undefined : true}
    >
      {children}
    </Tag>
  )
}

function normalizeDimension(value: string | number | undefined) {
  if (value === undefined) {
    return undefined
  }

  return typeof value === "number" ? `${value}px` : value
}
