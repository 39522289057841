import React from "react"

import { cn } from "@supernovaio/dm/src/utils/cn"

import { DMSpacing } from "../../types/spacing"

import { wellSpacing } from "./utils"

export type DMWellProps = {
  children: React.ReactNode
  className?: string
  variant?: "neutral-faded" | "neutral" | "base" | "critical" | "upgrade"
  bleed?: DMSpacing
  padding?: DMSpacing
}

// Cannot be used in some cases (Figma frames picker modal in docs) due to the span wrapper
export function DMWell(props: DMWellProps) {
  const {
    children,
    variant = "neutral",
    bleed = 0,
    padding = 12,
    className,
  } = props

  const colorVariants = {
    neutral: "bg-neutral",
    "neutral-faded": "bg-neutral-faded",
    critical: "bg-critical-faded border border-critical-faded",
    upgrade: "bg-upgrade-faded",
    base: "bg-elevation-base border border-neutral-faded",
  }

  const rootClassName = cn(
    colorVariants[variant],
    wellSpacing[padding].padding,
    bleed ? `rounded-none border-x-0` : "rounded",
    bleed ? wellSpacing[bleed].bleed : null,
    className
  )

  return (
    <div className={rootClassName}>
      <span>{children}</span>
    </div>
  )
}
