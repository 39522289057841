import React from "react"

import { Button } from "../../primitives/Button"
import { DMButtonBaseProps } from "../../primitives/Button/Button.types"

import type { ActionableRef } from "reshaped/bundle"

function DMButtonNeutralBase(props: DMButtonBaseProps, ref: ActionableRef) {
  const {
    variant = "solid",
    size = "medium",
    isLoading = false,
    isDisabled = false,
    isHighlighted = false,
    children,
    ...rest
  } = props

  return (
    <Button
      ref={ref}
      isDisabled={isDisabled}
      isHighlighted={isHighlighted}
      isLoading={isLoading}
      size={size}
      variant={variant}
      {...rest}
    >
      {children || ""}
    </Button>
  )
}

const DMButtonNeutral = React.forwardRef(
  DMButtonNeutralBase
) as React.ForwardRefExoticComponent<DMButtonBaseProps>

export { DMButtonNeutral }
