import React from "react"

import { cn } from "@supernovaio/dm/src/utils/cn"

export type DMLoaderProps = {
  size?: "mini" | "small" | "medium"
  color?: "primary" | "critical" | "positive" | "upgrade" | "inherit"
  className?: string
  ariaLabel?: string
}

const colors = {
  primary: {
    left: "border-primary",
    right: "border-primary opacity-30",
  },
  critical: {
    left: "border-critical",
    right: "border-critical opacity-30",
  },
  positive: {
    left: "border-positive",
    right: "border-positive opacity-30",
  },
  upgrade: {
    left: "border-upgrade",
    right: "border-upgrade opacity-30",
  },
  inherit: {
    left: "border-inherit",
    right: "border-inherit opacity-30",
  },
}

const borderWidthClasses = {
  mini: "border",
  small: "border-2",
  medium: "border-2",
}

export function DMLoader(props: DMLoaderProps) {
  const { size = "small", color = "inherit", ariaLabel, className } = props
  let sizeClasses = "h-icon-small w-icon-small"

  if (size === "medium") {
    sizeClasses = "h-icon-medium w-icon-medium"
  } else if (size === "mini") {
    sizeClasses = "w-[8px] h-[8px]"
  }

  return (
    <span
      aria-label={ariaLabel}
      aria-live={ariaLabel ? "assertive" : undefined}
      className={cn("relative block animate-spin", sizeClasses, className)}
      role="progressbar"
    >
      <span
        className={cn(
          borderWidthClasses[size],
          "absolute left-0 top-0 h-full w-[calc(50%+1px)] rounded-l-full border-r-0",
          colors[color].left
        )}
      />
      <span
        className={cn(
          borderWidthClasses[size],
          "absolute right-0 top-0 h-full w-[calc(50%+1px)] rounded-r-full border-l-0",
          colors[color].right
        )}
      />
    </span>
  )
}
