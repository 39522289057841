import React from "react"

import { Button } from "../../primitives/Button"
import { DMButtonBaseProps } from "../../primitives/Button/Button.types"
import { VariantClasses } from "../DMButton.types"

import type { ActionableRef } from "reshaped/bundle"

function DMButtonPrimaryBase(props: DMButtonBaseProps, ref: ActionableRef) {
  const {
    variant = "solid",
    size = "medium",
    isFullWidth = false,
    isLoading = false,
    isHighlighted = false,
    children,
    ...rest
  } = props

  const variantClasses: VariantClasses = {
    solid: {
      base: "bg-primary text-on-primary",
      hover: "hover:bg-primary-highlighted",
      focus: "focus-visible:ring-2",
      highlighted: "bg-primary-highlighted",
    },
    outline: {
      base: "bg-transparent text-primary border-primary-faded",
      hover: "hover:bg-primary-faded",
      focus: "focus-visible:ring-2",
      highlighted: "bg-primary-faded",
    },
    ghost: {
      base: "bg-transparent text-primary",
      hover: "hover:bg-primary-faded",
      focus: "focus-visible:ring-2",
      highlighted: "bg-primary-faded",
    },
    faded: {
      base: "bg-primary-faded text-primary",
      hover: "hover:border-primary-faded",
      focus: "focus-visible:ring-2",
      highlighted: "border-primary-faded",
    },
  }

  return (
    <Button
      ref={ref}
      isFullWidth={isFullWidth}
      isHighlighted={isHighlighted}
      isLoading={isLoading}
      size={size}
      variant={variant}
      variantStyles={variantClasses[variant]}
      {...rest}
    >
      {children || ""}
    </Button>
  )
}

const DMButtonPrimary = React.forwardRef(
  DMButtonPrimaryBase
) as React.ForwardRefExoticComponent<DMButtonBaseProps>

DMButtonPrimary.displayName = "DMButtonPrimary"

export { DMButtonPrimary }
