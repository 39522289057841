"use client"

import { useCallback } from "react"

export function useSupport() {
  const memoizedOpenChat = useCallback(() => {
    window.zE?.("messenger", "show")
    window.zE?.("messenger", "open")
  }, [])

  const memoizedInit = useCallback(() => {
    // Configure the whole layer (including the bubble) to disappear when the user closes the chat:
    window.zE?.("messenger:on", "close", () => {
      window.zE?.("messenger", "hide")
    })

    // Hide the layer on init:
    window.zE?.("messenger", "hide")
  }, [])

  return {
    initIntegration: memoizedInit,
    openChat: memoizedOpenChat,
  }
}
