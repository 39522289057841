import * as React from "react"
import { SVGProps } from "react"

export const IconRefresh = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={16}
    height={16}
    fill="none"
    viewBox="0 0 16 16"
    {...props}
  >
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.1}
      d="M13.333 7.333A5.4 5.4 0 0 0 3 6m-.333-2.667V6h2.666M2.667 8.667A5.4 5.4 0 0 0 13 10m.333 2.667V10h-2.666"
    />
  </svg>
)
