import React from "react"

import { Props } from "./DMIcon.types"

import { classNames } from "reshaped/bundle"

function DMIcon(props: Props) {
  const { svg: Component, className, color, size = "small", autoWidth } = props

  const getWidthClass = () => {
    if (size === "large") return "w-[32px]"
    if (size === "small") return "w-icon-small"
    return "w-icon-medium"
  }

  const getHeightClass = () => {
    if (size === "large") return "h-[32px]"
    if (size === "small") return "h-icon-small"
    return "h-icon-medium"
  }

  const getColorClass = () => {
    switch (color) {
      case "neutral":
        return "text-neutral"
      case "neutral-faded":
        return "text-neutral-faded"
      case "neutral-placeholder":
        return "text-neutral-placeholder"
      case "primary":
        return "text-primary"
      case "critical":
        return "text-critical"
      case "warning":
        return "text-warning"
      case "positive":
        return "text-positive"
      case "disabled":
        return "text-disabled"
      case "white":
        return "text-white"
      // List of all decorative colors
      case "green":
        return "text-decorative-green"
      case "red":
        return "text-decorative-red"
      case "orange":
        return "text-decorative-orange"
      case "blue":
        return "text-decorative-blue"
      case "purple":
        return "text-decorative-purple"
      case "cyan":
        return "text-decorative-cyan"
      case "pink":
        return "text-decorative-pink"
      case "yellow":
        return "text-decorative-yellow"
      case "grey":
        return "text-decorative-grey"
      case "teal":
        return "text-decorative-teal"
      case "fuchsia":
        return "text-decorative-fuchsia"
      case "lightGrey":
        return "text-decorative-lightGrey"
      default:
        return "text-inherit"
    }
  }

  const rootClassName = classNames(
    "flex shrink-0",
    autoWidth
      ? `w-auto aspect-auto justify-start`
      : `${getWidthClass()} aspect-square justify-center`,
    getHeightClass(),
    getColorClass(),
    className
  )

  // applying classname to svg element
  const icon =
    typeof Component === "object" ? (
      Component
    ) : (
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      <Component className="h-full w-auto min-w-full" />
    )

  return (
    // All icons are decorative, a11y attributes should be set for buttons wrapping them
    <span aria-hidden="true" className={rootClassName}>
      {React.cloneElement(icon, { focusable: false })}
    </span>
  )
}

export default DMIcon
