import { DMSpacing } from "../../types/spacing"

type WellSpacing = {
  [key in DMSpacing]: {
    padding: string
    bleed: string | null
  }
}

// This needs to exist because otherwise Tailwind doesn't include the classes in the build if they're only dynamically generated
export const wellSpacing: WellSpacing = {
  0: {
    padding: "p-12",
    bleed: null,
  },
  4: {
    padding: "p-4",
    bleed: "-mx-4 px-4",
  },
  8: {
    padding: "p-8",
    bleed: "-mx-8 px-8",
  },
  12: {
    padding: "p-12",
    bleed: "-mx-12 px-12",
  },
  16: {
    padding: "p-16",
    bleed: "-mx-16 px-16",
  },
  20: {
    padding: "p-20",
    bleed: "-mx-20 px-20",
  },
  24: {
    padding: "p-24",
    bleed: "-mx-24 px-24",
  },
  28: {
    padding: "p-28",
    bleed: "-mx-28 px-28",
  },
  32: {
    padding: "p-32",
    bleed: "-m-32 px-32",
  },
  36: {
    padding: "p-36",
    bleed: "-mx-36 px-36",
  },
  40: {
    padding: "p-40",
    bleed: "-mx-40 px-40",
  },
}
