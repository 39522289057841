"use client"

import { useState, useEffect } from "react"

import { getIsImpersonating } from "./utils"

/** Works only on client side. On server side it will behave like impersonating is disabled. */
export function useImpersonation() {
  const [isImpersonating, setIsImpersonating] = useState(false)

  useEffect(() => {
    if (getIsImpersonating()) {
      setIsImpersonating(true)
    }
  }, [])

  return {
    isImpersonating,
  }
}
