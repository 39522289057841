"use client"

import React, {
  createContext,
  type ReactNode,
  useCallback,
  useContext,
  useMemo,
  useState,
} from "react"

type DMToastsContextValue = {
  registerToast: (toastId: string) => void
  unregisterToast: (toastId: string) => void
  toastsIds: string[]
}

type DMToastsContextProviderProps = {
  children: ReactNode
}

const DMToastsContext = createContext<DMToastsContextValue>({
  registerToast: () => {},
  unregisterToast: () => {},
  toastsIds: [],
})

export function DMToastsContextProvider({
  children,
}: DMToastsContextProviderProps) {
  const [toastsIds, setToastsIds] = useState<string[]>([])

  const registerToast = useCallback((toastId: string) => {
    setToastsIds((prevToastsIds) => [...prevToastsIds, toastId])
  }, [])

  const unregisterToast = useCallback((toastId: string) => {
    setToastsIds((prevToastsIds) =>
      prevToastsIds.filter((prevToastId) => prevToastId !== toastId)
    )
  }, [])

  const memoizedValue = useMemo(
    () => ({ registerToast, unregisterToast, toastsIds }),
    [registerToast, unregisterToast, toastsIds]
  )

  return (
    <DMToastsContext.Provider value={memoizedValue}>
      {children}
    </DMToastsContext.Provider>
  )
}

export function useDMToastsContext() {
  return useContext(DMToastsContext)
}
