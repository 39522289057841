/**
 * Normalizes a test ID by replacing all non-alphanumeric characters with underscores
 * and converting camelCase to snake_case. This helps in creating valid HTML attributes.
 *
 * @param {string | undefined} testId - The test ID to normalize.
 * @returns {string | undefined} The normalized test ID or undefined if the input is undefined.
 */
export const normalizeTestId = (testId?: string): string | undefined => {
  if (testId === undefined) return undefined
  return testId
    .replace(/([a-z])([A-Z])/g, "$1_$2") // Convert camelCase to snake_case
    .replace(/[^a-zA-Z0-9]/g, "_")
    .toLowerCase()
}
