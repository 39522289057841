import { useCallback } from "react"

import { useDMToast } from "@supernovaio/dm"

import {
  createErrorToastEvent,
  useSegment,
} from "@supernovaio/cloud/features/segment"
import { SupernovaError } from "@supernovaio/sdk"

import { handleError } from "../utils/errorHandler"

export const useErrorHandler = () => {
  const { show: showToast } = useDMToast()
  const { trackEvent } = useSegment()

  /**
   * Use this handler to report and handle the exception that was caught by UI error boundary.
   * This will only silently report the error as the exception was already shown by the boundary
   */
  const handleBoundaryError = handleError

  /** Use this handler to report error thrown by mutation. This will show error as global toast */
  const handleMutationError = useCallback(
    (error: SupernovaError | Error) => {
      showToast({
        text: error.message,
        preset: "error",
      })

      trackEvent(
        createErrorToastEvent({
          failedAction: "mutation",
          domain: "system",
          errorInfo: "hook_use_error_handler",
          errorText: error.message,
        })
      ).catch((trackingError) => {
        console.error("Failed to track error event:", trackingError)
      })

      handleError(error)
      // onError(error, error.formErrors ?? []) // Potentially forwards error to form error handler later
    },
    [showToast, trackEvent]
  )

  return { handleMutationError, handleBoundaryError }
}
