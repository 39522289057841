"use client"

import type { ReactNode } from "react"

import { cn } from "@supernovaio/dm/src/utils/cn"

type Props = {
  children: ReactNode
  hasBackground?: boolean
}

/** Full-screen layout whose child will always automatically center in the middle. Great for sign-in, sign-up, loaders etc. */
export function PageLayoutTypeCentering({
  children,
  hasBackground = true,
}: Props) {
  return (
    <div
      className={cn("flex h-full w-full flex-row items-center justify-center", {
        "bg-neutral": hasBackground,
      })}
    >
      {children}
    </div>
  )
}
