"use client"

import React from "react"

import type * as T from "./Actionable.types"
import { OnClickType } from "./Actionable.types"

import { classNames } from "reshaped/utilities/helpers"

export type ActionablePropsForRadix = T.ActionableProps & {
  actionableTag?: React.ElementType
  onPointerDown?: React.PointerEventHandler
  isExternalLink?: boolean
}

function Actionable(props: ActionablePropsForRadix, ref: T.ActionableRef) {
  const {
    actionableTag,
    children,
    href,
    onClick,
    onPointerDown,
    onPointerUp,
    type,
    isDisabled,
    as,
    className,
    attributes,
    ariaLabel,
    isExternalLink,
    onMouseEnter,
    onMouseLeave,
    ...rest
  } = props

  const rootClassNames = classNames(
    "outline-0 focus-visible:outline-0 transition no-underline select-none",
    className,
    isDisabled && "cursor-not-allowed pointer-events-none",
    "motion-reduce:transition-none motion-reduce:hover:transform-none"
  )

  const rootAttributes: T.ActionableProps["attributes"] = {
    ...attributes,
    ...rest,
  }

  const clickHandler = (onPointerDown ||
    onClick ||
    attributes?.onClick ||
    (() => {})) as (event: OnClickType) => void

  const focusHandler = attributes?.onFocus || attributes?.onBlur
  const isLink = Boolean(href || attributes?.href)
  const isButton = Boolean(clickHandler || focusHandler || type)
  let TagName: React.ElementType<any> = "span"

  if (isLink) {
    rootAttributes.href = isDisabled ? "" : href || attributes?.href
    rootAttributes.target = isExternalLink ? "_blank" : undefined
    rootAttributes.rel = isExternalLink ? "noopener noreferrer" : undefined
    TagName = "a"
  } else if (isButton && (!as || as === "button")) {
    TagName = "button"
    rootAttributes.type = type || attributes?.type || "button"
    rootAttributes.disabled = isDisabled || attributes?.disabled
  } else if (isButton) {
    const isFocusable = as === "label"
    const simulateButton = !isFocusable || clickHandler || focusHandler

    TagName = as || "span"
    rootAttributes.role = simulateButton ? "button" : undefined
    rootAttributes.tabIndex = simulateButton ? 0 : undefined
  } else {
    TagName = as || "span"
  }

  if (actionableTag === "div") {
    rootAttributes.role = "button"
    rootAttributes.type = undefined
  }

  const handlePress: T.ActionableProps["onClick"] = (event: OnClickType) => {
    if (clickHandler && !isDisabled) {
      clickHandler(event)
    }
  }

  const handleKeyDown = (event: React.KeyboardEvent<HTMLElement>) => {
    const simulatingButton = rootAttributes.role === "button"

    if (!simulatingButton || isLink) return

    const isSpace = event.key === " "
    const isEnter = event.key === "Enter"

    if (!isSpace && !isEnter) return

    event.preventDefault()
    handlePress(event as unknown as React.MouseEvent<HTMLElement>)
  }

  const ActualTagName = actionableTag || TagName

  return (
    <ActualTagName
      ref={ref}
      // rootAttributes can receive ref from Flyout
      {...rootAttributes}
      aria-label={ariaLabel}
      className={rootClassNames}
      onClick={handlePress}
      onKeyDown={handleKeyDown}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      onPointerDown={onPointerDown}
      onPointerUp={onPointerUp}
    >
      {children}
    </ActualTagName>
  )
}

export default React.forwardRef(Actionable)
