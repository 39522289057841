import * as React from "react"
import { SVGProps } from "react"

export const IconSupport = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={16}
    height={16}
    fill="none"
    viewBox="0 0 16 16"
    {...props}
  >
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.1}
      d="M6.333 6h.007m3.327 0h.006m-3.34 2.667a2.333 2.333 0 0 0 3.334 0m-7 5.333V5.333a2 2 0 0 1 2-2h6.666a2 2 0 0 1 2 2v4a2 2 0 0 1-2 2h-6z"
    />
  </svg>
)
