import { SegmentEventBase } from "@supernovaio/cloud/features/segment/types"

interface ErrorToastEventProps {
  failedAction: string
  domain: string
  errorInfo?: string
  errorText?: string
}

export function createErrorToastEvent({
  failedAction,
  domain,
  errorInfo,
  errorText,
}: ErrorToastEventProps): SegmentEventBase {
  return {
    eventName: "error_toast_displayed",
    failed_action: failedAction,
    domain,
    error_info: errorInfo,
    error_text: errorText,
  }
}
